
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class AddNewSeasonModal extends Vue {

    @Prop() saveCallback: (updatedModel: string) => void;

    seasonName: string = "";

    created() {}

    save() {
        this.saveCallback(this.seasonName);
    }
}
