
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class AddNewGarmentModelModal extends Vue {

    @Prop() saveCallback: (updatedModel: string) => void;

    garmentModelName: string = "";

    created() {}

    save() {
        this.saveCallback(this.garmentModelName);
    }
}
