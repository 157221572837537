
import { Options, Vue } from 'vue-class-component';
import { CompanyClient, EstimationClient } from '@/services/Services';
import { Prop } from 'vue-property-decorator';
import AddNewSeasonModal from '../modals/addNewSeasonModal.vue';
import AddNewGarmentModelModal from '../modals/addNewGarmentModelModal.vue';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class UpdateCollectionInfo extends Vue {

    @Prop() estimationIdentifier: string;

    companySeasonList: OM.TextIdentifier[] = [];
    companyModelList: OM.TextIdentifier[] = [];

    model: OM.CollectionInfoVM = new OM.CollectionInfoVM();

    created() {
        Promise.all([
            CompanyClient.getSeasonsByCompany(),
            CompanyClient.getModelsByCompany(),
            EstimationClient.getCollectionInfo(this.estimationIdentifier)
        ])
        .then( xs => {
            this.companySeasonList = xs[0];
            this.companyModelList = xs[1];

            this.model.estimationIdentifier = this.estimationIdentifier;
            this.model.garmentModelIdentifier = xs[2].garmentModelIdentifier;
            this.model.seasonIdentifier = xs[2].seasonIdentifier;
            this.model.quantity = xs[2].quantity;
        })
    }

    addNewSeason() {
        this.$opModal.show(AddNewSeasonModal, {
            saveCallback: ((seasonName: string) => {
                CompanyClient.createNewSeason(seasonName)
                .then(x => {
                    var newTextIdForList = new OM.TextIdentifier();
                    newTextIdForList.identifier = x;
                    newTextIdForList.text = seasonName;
                    this.companySeasonList.push(newTextIdForList);
                    this.model.seasonIdentifier = x;

                    this.$opModal.closeLast();
                })
            })
        })
    }

    addNewModel() {
        this.$opModal.show(AddNewGarmentModelModal, {
            saveCallback: ((modelName: string) => {
                CompanyClient.createNewGarmentModel(modelName)
                .then(x => {
                    var newTextIdForList = new OM.TextIdentifier();
                    newTextIdForList.identifier = x;
                    newTextIdForList.text = modelName;
                    this.companyModelList.push(newTextIdForList);
                    this.model.garmentModelIdentifier = x;

                    this.$opModal.closeLast();
                })
            })
        })
    }

    get disabled(){
        return !this.model.estimationIdentifier || !this.model.garmentModelIdentifier || !this.model.garmentModelIdentifier || this.model.quantity < 1;
    }

    updateCollectionInfo() {
        EstimationClient.updateCollectionInfo(this.model)
        .then(x => {
            this.$emit('goToNextSection');
        })
    }
}
